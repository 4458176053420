import React from "react";
import ContactsTable from "../groups/ContactTable";
import NestedList from "../groups/Nestedlist";

const TeamMembers = () => {
  const listData = [
    {
      name: "Level 1",
      children: [
        {
          name: "Level 2.1",
          children: [
            {
              name: "Level 3.1",
              children: [],
            },
            {
              name: "Level 3.2",
              children: [
                {
                  name: "Level 4.1",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          name: "Level 2.2",
          children: [],
        },
      ],
    },
    {
      name: "Level 1.2",
      children: [],
    },
  ];

  return (
    <div className="container">
      <div className="content">
        {/*<NestedList data={listData} />*/}
        <ContactsTable />
      </div>
    </div>
  );
};

export default TeamMembers;
