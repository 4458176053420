import React from "react";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

const ExportToExcel = ({ data, fileName, buttonName = "Export to Excel" }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const flatData = [];

  const flattenData = (data) => {
    let flattened = [];

    const processNode = (node, parentName) => {
      const { children, ...rest } = node;
      flattened.push({ ...rest, parent_name: parentName });

      if (children && children.length > 0) {
        children.forEach((child) => processNode(child, node.name));
      }
    };

    data.forEach((item) => processNode(item, null));

    return flattened;
  };

  const exportToExcel = async () => {
    const flattenedData = flattenData(data);

    // Sort the flattened data by parent_name
    flattenedData.sort((a, b) => {
      if (a.parent_name === b.parent_name) {
        return 0;
      }
      if (a.parent_name === null) {
        return -1;
      }
      if (b.parent_name === null) {
        return 1;
      }
      return a.parent_name.localeCompare(b.parent_name);
    });

    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const excelBuffer = XLSX.write(
      {
        Sheets: { data: ws },
        SheetNames: ["data"],
      },
      { bookType: "xlsx", type: "array" }
    );
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, fileName + fileExtension);
  };

  return (
    <button
      onClick={() => {
        exportToExcel(fileName);
      }}
    >
      {buttonName}
    </button>
  );
};

export default ExportToExcel;
