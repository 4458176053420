import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import CsvUpload from "./pages/CSVUpload";
import Dashboard from "./pages/Dashboard";
import TeamMembers from "./pages/TeamMembers";

function App() {
  return (
    <Router>
      <div>
        <Sidebar />
        <div style={{ marginLeft: "200px", padding: "16px" }}>
          <Routes>
            <Route path="/teammembers" element={<TeamMembers />} />
            <Route path="/csvupload" element={<CsvUpload />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
