import React, { useState } from "react";
import axios from "axios";
import { URL } from "../constants";
import LoadingScreen from "../components/LoadingScreen";

const CsvUpload = () => {
  const [contactfile, setContactFile] = useState(null);
  const [orderfile, setOrderFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFileContactChange = (e) => {
    setContactFile(e.target.files[0]);
  };

  const onFileOrderChange = (e) => {
    setOrderFile(e.target.files[0]);
  };

  const onContactUpload = async () => {
    if (!contactfile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", contactfile);

    try {
      setIsLoading(true);
      await axios
        .post(`${URL}/upload_csv_contact`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            setIsLoading(false);
            alert("File uploaded successfully.");
            window.location.reload();
          } else if (response.data.status === "fail") {
            setIsLoading(false);
            alert(
              `Error Uploading File.\nError: ${response.data.error}\nLine:${response.data.line}`
            );
            window.location.reload();
          }
        })
        .catch((error) => alert(error));
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      alert("Error uploading the file." + error);
    }
  };

  const onOrderUpload = async () => {
    if (!orderfile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", orderfile);

    try {
      setIsLoading(true);
      await axios
        .post(`${URL}/upload_csv_order`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            setIsLoading(false);
            alert("File uploaded successfully.");
            window.location.reload();
          } else if (response.data.status === "fail") {
            setIsLoading(false);
            alert(
              `Error Uploading File.\nError: ${response.data.error}\nLine:${response.data.line}`
            );
            window.location.reload();
          }
        })
        .catch((error) => alert(error));
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      alert("Error uploading the file." + error);
    }
  };

  return !isLoading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        height: "200px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <h2>Upload Contacts</h2>
        <input type="file" accept=".csv" onChange={onFileContactChange} />
        <button onClick={onContactUpload}>Upload Contacts CSV</button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <h2>Upload Orders</h2>
        <input type="file" accept=".csv" onChange={onFileOrderChange} />
        <button onClick={onOrderUpload}>Upload Orders CSV</button>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default CsvUpload;
