import React from "react";
import { NavLink } from "react-router-dom";
//import "./styles.css";

const Sidebar = () => {
  const menuItems = [
    { name: "Team Members", path: "/teammembers" },
    { name: "CSV Upload", path: "/csvupload" },
  ];

  const appVersion = "1.0.0";

  return (
    <div className="sidebar">
      <div className="sidebar-logo">Alfar Global</div>
      <ul className="sidebar-menu">
        {menuItems.map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.path}
              className="sidebar-menu-item"
              activeClassName="sidebar-menu-item-active"
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="app-version" style={{ marginTop: "auto" }}>
        Version {appVersion}
      </div>
    </div>
  );
};

export default Sidebar;
