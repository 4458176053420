import React from "react";
import PropTypes from "prop-types";

const Pagination = ({ pageCount, currentPage, onChange }) => {
  const handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    onChange(pageNumber);
  };

  const renderPageButton = (pageNumber) => {
    return (
      <button
        key={pageNumber}
        className={`btn btn-link ${currentPage === pageNumber ? "active" : ""}`}
        onClick={(event) => handlePageChange(event, pageNumber)}
      >
        {pageNumber}
      </button>
    );
  };

  const renderPagination = () => {
    const buttons = [];
    const maxButtons = 4; // Set the maximum number of buttons to display
    const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    const endPage = Math.min(pageCount, startPage + maxButtons - 1);

    // Add support for rendering the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          key={1}
          className={`btn btn-link`}
          onClick={(event) => handlePageChange(event, 1)}
        >
          {1}
        </button>
      );
      // Add an ellipsis before the first button if there are additional pages
      if (startPage > 2) {
        buttons.push(<span key="ellipsis1">&nbsp;&nbsp;...</span>);
      }
    }

    // Render all the page buttons between the start and end pages
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(renderPageButton(i));
    }

    // Add an ellipsis after the last button if there are additional pages
    if (endPage < pageCount - 1) {
      buttons.push(<span key="ellipsis2">&nbsp;&nbsp;...</span>);
    }
    // Add support for rendering the last page button
    if (endPage < pageCount) {
      buttons.push(
        <button
          key={pageCount}
          className={`btn btn-link`}
          onClick={(event) => handlePageChange(event, pageCount)}
        >
          {pageCount}
        </button>
      );
    }
    return buttons;
  };

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={(event) => handlePageChange(event, currentPage - 1)}
          >
            Previous
          </button>
        </li>
        {renderPagination()}
        <li
          className={`page-item ${currentPage === pageCount ? "disabled" : ""}`}
        >
          <button
            className="page-link"
            onClick={(event) => handlePageChange(event, currentPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
