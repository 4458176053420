import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import axios from "axios";
import { URL } from "../constants";
import ChildContacts from "./ChildContacts";
import ExportToExcel from "../components/ExportToExcel";
import Loading from "./Loading";

function ContactTable(props) {
  const [contacts, setContacts] = useState([]);
  const [contactsComplete, setContactsComplete] = useState([]);
  const [ordersComplete, setOrdersComplete] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalContacts, setTotalContacts] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [depthLimit, setDepthLimit] = useState(1);

  const handleDepthLimitChange = (event) => {
    setDepthLimit(parseInt(event.target.value));
  };

  const limit = 15; // set default limit to 10 if props.limit is undefined

  const fetchContacts = async (page, limit, searchTerm = "") => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${URL}/getContacts?page=${page}&limit=${limit}&searchTerm=${searchTerm}&startDate=${startDate ||
          "2022-01-01"}&endDate=${endDate ||
          "2023-12-31"}&depthLimit=${depthLimit}`
      );
      const { data } = response;
      setContacts(data.data);
      setTotalContacts(data.totalCount);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchContactsComplete = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${URL}/getContactsComplete?&startDate=${startDate ||
          "2022-01-01"}&endDate=${endDate || "2023-03-27"}`
      );
      const { data } = response;
      setContactsComplete(data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOrdersComplete = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${URL}/getOrdersComplete?&startDate=${startDate ||
          "2022-01-01"}&endDate=${endDate || "2023-03-27"}`
      );
      const { data } = response;
      setOrdersComplete(data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    fetchContacts(currentPage, limit, searchTerm, startDate, endDate);
  }, [currentPage, limit, searchTerm, startDate, endDate, depthLimit]);

  useEffect(() => {
    fetchContactsComplete();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchOrdersComplete();
  }, [startDate, endDate]);

  useEffect(() => {
    getChildContacts();
  }, [depthLimit]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchContacts(pageNumber, limit);
    setSelectedContactId(null);
    fetchContactsComplete();
    fetchOrdersComplete();
  };
  const [loading, setLoading] = useState(false);
  const getChildContacts = (contactId) => {
    setLoading(true);
    axios
      .get(
        `${URL}/getChildContacts?contact_id=${contactId}&startDate=${startDate ||
          "2022-01-01"}&endDate=${endDate ||
          "2023-03-27"}&depthLimit=${depthLimit}`
      )
      .then((response) => {
        setChildContacts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Turn off loading state even if an error occurs
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchContacts(pageNumber, limit);
  };

  const renderChildContacts = () => {
    if (
      selectedContactId === undefined ||
      childContacts === undefined ||
      !selectedContactId ||
      !childContacts.length
    ) {
      return null;
    }

    /*const isDepthLimitReached = () => {
      if (depthLimit){

      }
    }*/

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <tbody>
            {childContacts.map((contact) => (
              <tr
                key={contact.contact_id}
                onClick={() => getChildContacts(contact.contact_id)}
              >
                <td>{contact.name}</td>
                <td>
                  {contact.subtotal.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {contact.discount.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const [childContacts, setChildContacts] = useState([]);

  const handleContactClick = (contactId) => {
    setSelectedContactId(selectedContactId === contactId ? null : contactId);
    getChildContacts(contactId);
  };

  const getRowStyle = (id) => {
    return selectedContactId === id
      ? { backgroundColor: "rgb(1 67 82 / 78%)", color: "#fff" }
      : {};
  };

  const renderContacts = () => {
    if (contacts === undefined || !contacts.length) {
      return <p>No contacts found.</p>;
    }

    return (
      <div className="table-responsive pd-t-10">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <td>Name</td>
              <td>Subtotal (Rs)</td>
              <td>Discount (Rs)</td>
              <td>Balance Due (Rs)</td>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <React.Fragment key={contact.contact_id}>
                <tr
                  onClick={() => handleContactClick(contact.contact_id)}
                  style={getRowStyle(contact.contact_id)}
                >
                  <td>{contact.name}</td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(contact.subtotal).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(contact.discount).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(contact.due).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                {selectedContactId === contact.contact_id && (
                  <tr>
                    <td colSpan="5">
                      <ChildContacts
                        contactId={contact.contact_id}
                        contactName={contact.name}
                        parentSubtotal={contact.subtotal}
                        firstLevelChildTotal={childContacts}
                        limit={limit}
                        startDate={startDate}
                        endDate={endDate}
                        depthLimit={depthLimit}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <Pagination
          pageCount={Math.ceil(totalContacts / limit)}
          currentPage={currentPage}
          onChange={handlePageChange}
        />
      </div>
    );
  };

  return (
    <div>
      {loading && <Loading />}
      <div>
        <h1>All Contacts</h1>
        <input
          type="text"
          placeholder="Search Contacts"
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
        <div>
          <div
            className="form-group"
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              value={startDate || ""}
              //value={"2022-11-01"}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div
            className="form-group"
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              value={endDate || ""}
              //value={"2022-11-30"}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div
            className="form-group"
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <label htmlFor="depthLimitSelect">Nested Child Limit:</label>
            <select
              className="form-control"
              id="depthLimitSelect"
              onChange={handleDepthLimitChange}
              defaultValue={depthLimit}
            >
              {/*<option value="0">No Limit</option>*/}
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
              <option value="4">Level 4</option>
              <option value="5">Level 5</option>
              <option value="6">Level 6</option>
              <option value="7">Level 7</option>
              <option value="8">Level 8</option>
              <option value="9">Level 9</option>
              <option value="10">Level 10</option>
              <option value="11">Level 11</option>
              <option value="12">Level 12</option>
              <option value="13">Level 13</option>
              <option value="14">Level 14</option>
              <option value="15">Level 15</option>
              <option value="16">Level 16</option>
              <option value="17">Level 17</option>
              <option value="18">Level 18</option>
              <option value="19">Level 19</option>
              <option value="20">Level 20</option>
              <option value="21">Level 21</option>
              <option value="22">Level 22</option>
              <option value="23">Level 23</option>
              <option value="24">Level 24</option>
              <option value="25">Level 25</option>
            </select>
          </div>
          <div
            className="form-group"
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <ExportToExcel
              data={contactsComplete}
              fileName="filename"
              buttonName="All Contacts Export"
            />
            <ExportToExcel
              data={ordersComplete}
              fileName="filename"
              buttonName="All Orders Export"
            />
          </div>
        </div>
        {renderContacts()}
      </div>
    </div>
  );
}

export default ContactTable;
