import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import axios from "axios";
import { URL } from "../constants";
import ExportToExcel from "../components/ExportToExcel";

function ChildContacts({
  contactId,
  contactName,
  parentSubtotal,
  firstLevelChildTotal,
  limit,
  startDate,
  endDate,
  depthLimit,
}) {
  const [childContacts, setChildContacts] = useState({});
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [lastClickedParent, setLastClickedParent] = useState(null);
  const [firstChildSubtotals, setFirstChildSubtotals] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${URL}/getChildContacts?contact_id=${contactId}&startDate=${startDate ||
          "2022-01-01"}&endDate=${endDate ||
          "2023-03-27"}&depthLimit=${depthLimit}`
      )
      .then((response) => {
        let noDuplicates = removesDuplicate(response.data.data);
        let formattedData = buildNestedJson(response.data.data);
        console.log(formattedData);
        setChildContacts(formattedData);
      })
      .catch((error) => console.log(error));

    setFirstChildSubtotals(removesDuplicate(firstLevelChildTotal));
  }, [contactId, startDate, endDate, depthLimit, firstLevelChildTotal]);

  const handleRowClick = (id) => {
    const itemHasChildren = childContacts.some(
      (item) => item.name === id && item.children && item.children.length > 0
    );

    if (expandedRows.has(id)) {
      expandedRows.delete(id);
      if (itemHasChildren) {
        setLastClickedParent(null);
      }
    } else {
      expandedRows.add(id);
      if (itemHasChildren) {
        setLastClickedParent(id);
      }
    }

    setExpandedRows(new Set(expandedRows));
  };

  const getRowStyle = (id, level, hasChildren) => {
    //const isExpanded = expandedRows.has(id);
    const isExpanded = expandedRows.has(id) && level > 0;
    const isParentWithVisibleChildren = hasChildren && isExpanded;
    const isChildWithVisibleChildren = !hasChildren && isExpanded;

    if (isParentWithVisibleChildren) {
      return { backgroundColor: "rgb(1 67 82 / 78%)", color: "#fff" };
    } else if (isChildWithVisibleChildren) {
      return { backgroundColor: "rgb(1 67 82 / 50%)", color: "#fff" };
    } else {
      return { color: "#000" };
    }
  };

  const getChildRowStyle = (parentId, level) => {
    return selectedContactId === parentId &&
      expandedRows.has(parentId) &&
      level > 0
      ? {
          backgroundColor: "rgb(1 67 82 / 30%)",
          color: "#000",
          cursor: "pointer",
        }
      : { cursor: "pointer" };
  };

  const removesDuplicate = (data) => {
    return data.filter((item, index, self) => {
      return index === self.findIndex((t) => t.name === item.name);
    });
  };

  function buildNestedJson(data) {
    const createNode = (item, parent_name = null) => ({
      contact_id: item.contact_id,
      name: parent_name || item.name,
      parent_id: item.parent_id,
      parent_name: item.parent_name,
      subtotal: item.subtotal_total,
      discount: item.discount,
      due: item.due,
      subtotal_order: item.subtotal_order,
      children: [],
    });

    const idToNodeMap = new Map();

    data.forEach((item) => {
      if (!idToNodeMap.has(item.name)) {
        const newNode = createNode(item);
        idToNodeMap.set(item.name, newNode);
      } else {
        const existingNode = idToNodeMap.get(item.name);
        if (item.subtotal_total !== undefined) {
          existingNode.subtotal = item.subtotal_total;
          existingNode.subtotal_order = item.subtotal_order;
          existingNode.discount = item.discount;
          existingNode.due = item.due;
        }
      }

      if (item.parent_name) {
        if (!idToNodeMap.has(item.parent_name)) {
          console.log(item);
          const newNode = createNode(item, item.parent_name);

          idToNodeMap.set(item.parent_name, newNode);
          console.log(idToNodeMap.get(item.parent_name));
        }
        idToNodeMap
          .get(item.parent_name)
          .children.push(idToNodeMap.get(item.name));
      }
    });

    const allNodes = Array.from(idToNodeMap.values());

    let correctArray = removeNestedChildren(allNodes);

    let allNodesWithSubtotal = updateAllSubtotals(correctArray);

    return allNodesWithSubtotal;
  }

  function updateSubtotal(obj) {
    if (obj.children.length > 0) {
      obj.children.forEach((child) => {
        obj.subtotal += updateSubtotal(child);
      });
    }
    return obj.subtotal;
  }

  function updateAllSubtotals(arr) {
    arr.forEach((obj) => {
      updateSubtotal(obj);
    });
    return arr;
  }

  function removeNestedChildren(data) {
    function extractAllChildIDs(items) {
      let childIDs = [];

      items.forEach((item) => {
        if (item.children && item.children.length > 0) {
          childIDs.push(...item.children.map((child) => child.name));
          childIDs.push(...extractAllChildIDs(item.children));
        }
      });

      return childIDs;
    }

    // Remove the objects whose IDs are present in the nested children
    function filterNestedChildren(items, childIDs) {
      return items.filter((item) => !childIDs.includes(item.name));
    }

    const allChildIDs = extractAllChildIDs(data);
    const filteredData = filterNestedChildren(data, allChildIDs);
    return filteredData;
  }

  const renderTableRows = (data, level = 0) => {
    return data.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      return (
        <React.Fragment key={item.name}>
          <tr
            onClick={() => {
              handleRowClick(item.name);
            }}
            style={getRowStyle(item.name, level, hasChildren)}
          >
            <td style={{ paddingLeft: `${30 * level || 10}px` }}>
              {item.name}
            </td>
            <td style={{ textAlign: "right" }}>
              {parseFloat(item.subtotal).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </td>
            <td style={{ textAlign: "right" }}>
              {parseFloat(item.subtotal_order).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </td>
            <td style={{ textAlign: "right" }}>
              {parseFloat(item.discount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </td>
            <td style={{ textAlign: "right" }}>
              {parseFloat(item.due).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>
          {expandedRows.has(item.name) &&
            item.children &&
            renderTableRows(item.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            textAlign: "center",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <div>
            <h3>Team: </h3>
            <h2></h2>
          </div>
          <div>
            <h3>{contactName}</h3>
          </div>
          {
            <div>
              <ExportToExcel data={childContacts} fileName="filename" />
            </div>
          }
        </div>
        {childContacts.length > 0 && (
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Subtotal (All Children Orders)</td>
                <td>Subtotal (Per Order)</td>
                <td>Discount</td>
                <td>Balance Due</td>
              </tr>
            </thead>
            <tbody>{renderTableRows(childContacts)}</tbody>
          </table>
        )}
        {childContacts.length === 0 && (
          <h1 style={{ textAlign: "center", color: "var(--primary-color)" }}>
            No Team Members
          </h1>
        )}
      </div>
    </React.Fragment>
  );
}

export default ChildContacts;
